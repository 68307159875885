import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { FormGroup, FormControl, FormLabel, Alert } from 'react-bootstrap';
import FlashMessage from 'react-flash-message';
import LoaderButton from '../shared/LoaderButton';
import { useAuthentication } from '../../contexts/authContext';

import './Login.css';

export default function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);
  const { signIn, userToken, isAuthenticating } = useAuthentication();

  const history = useHistory();
  const referer = props.location.state ? props.location.state.referer : '/';

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setMessage(null);
    try {
      await signIn({ email, password }).then(() => {
        history.push(referer);
      });
    } catch (e) {
      setMessage(e.message);
    }
  }

  if (userToken != null) {
    return <Redirect to={referer} />;
  }

  return (
    <div className="Login">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" size="lg">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="password" size="lg">
          <FormLabel>Password</FormLabel>
          <FormControl
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          size="lg"
          isLoading={isAuthenticating}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>

        {message != null && (
          <FlashMessage duration={4000}>
            <Alert variant="danger">{message}</Alert>
          </FlashMessage>
        )}
      </form>
    </div>
  );
}
