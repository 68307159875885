import React, { useEffect } from 'react';
import axios from 'axios';
import { useAuthentication } from '../contexts/authContext';

const restApi = axios.create();
const { REACT_APP_API_PROJECTS_KEY } = process.env;
let axiosInterceptorReq = null; // outer variable
let axiosInterceptorRes = null; // outer variable

export default () => {
  const { refreshToken, userToken } = useAuthentication();

  useEffect(() => {
    const interceptTraffic = () => {
      if (!!axiosInterceptorReq || axiosInterceptorReq === 0) {
        restApi.interceptors.request.eject(axiosInterceptorReq);
      }

      axiosInterceptorReq = restApi.interceptors.request.use(
        config => {
          const uToken = `Bearer ${userToken}`;
          config.headers = {
            Authorization: uToken,
            'x-api-key': `${REACT_APP_API_PROJECTS_KEY}`,
            Accept: 'application/json',
          };
          return config;
        },
        error => {
          Promise.reject(error);
        }
      );

      if (!!axiosInterceptorRes || axiosInterceptorRes === 0) {
        restApi.interceptors.request.eject(axiosInterceptorRes);
      }

      axiosInterceptorRes = restApi.interceptors.response.use(
        response => {
          // Return a successful response back to the calling service
          return response;
        },
        error => {
          // Return any error which is not due to authentication back to the calling service
          if (error.response.status !== 403) {
            return new Promise((resolve, reject) => {
              reject(error);
            });
          }

          // Try request again with new token
          return refreshToken()
            .then(token => {
              // New request with new token
              const config = error.config;
              config.headers['Authorization'] = `Bearer ${token}`;

              return new Promise((resolve, reject) => {
                axios
                  .request(config)
                  .then(response => {
                    resolve(response);
                  })
                  .catch(error => {
                    reject(error);
                  });
              });
            })
            .catch(error => {
              Promise.reject(error);
            });
        }
      );
    };
    interceptTraffic();
  }, [userToken, refreshToken]);

  return <div />;
};

export { restApi };
