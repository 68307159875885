import React, { useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { restApi } from '../../client/Http';

export default function Projects(props) {
  const getProjects = () => {
    try {
      restApi.get('https://dev.api.gtavos.com/projects').then(res => {
        console.log(res);
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div>
      <div>
        <h2>Projects</h2>
      </div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
          </tr>
          <tr>
            <td>3</td>
            <td colSpan="2">Larry the Bird</td>
            <td>
              <Button onClick={getProjects}>Get Projects</Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
