const {
  REACT_APP_AWS_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  REACT_APP_COGNITO_IDENTITY_POOL_ID,
} = process.env;

const awsConfig = {
  identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
  region: REACT_APP_AWS_REGION,
  userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  mandatorySignIn: true,
};

export default awsConfig;
