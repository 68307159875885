import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthentication } from '../contexts/authContext';

function PrivateRoute({ component: Component, ...rest }) {
  const { userToken } = useAuthentication();

  return (
    <Route
      {...rest}
      render={props =>
        userToken != null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { referer: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
