import React, { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { SidebarData } from './menu/SideMenuData';
import './menu/SideMenu.css';
import { IconContext } from 'react-icons';
import { useAuthentication } from '../../contexts/authContext';

export default function Header() {
  const { signOut, userToken } = useAuthentication();
  const history = useHistory();

  async function handleLogout() {
    await signOut().then(() => {
      history.push('/login');
    });
  }

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <Navbar bg="light" variant="light">
        <Nav.Link>
          <FaIcons.FaBars color="grey" size={20} onClick={showSidebar} />
        </Nav.Link>
        <Navbar.Brand href="/">GS</Navbar.Brand>
        <Nav className="ml-auto" navbar pills="true">
          {userToken != null ? (
            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
          ) : (
            <>
              <Nav.Link href="/login">Login</Nav.Link>
              <Nav.Link href="/signup">Signup</Nav.Link>
            </>
          )}
        </Nav>
      </Navbar>

      <IconContext.Provider value={{ color: '#fff' }}>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}
