import React, { useReducer, useMemo, useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { authReducer } from './authReducer';
import { AuthContext, useAuthContext } from './contextLib';
import awsConfig from './utils/aws-exports';
//import Cookies from 'js-cookie';

Amplify.configure({ Auth: awsConfig });

export const AuthProvider = ({ children, ...props }) => {
  // set up our reducer, a function that handles our business logic around our
  // authentication state via events sent using dispatch
  const [state, dispatch] = useReducer(authReducer, {
    isAuthenticating: true,
    isSignout: false,
    userToken: null,
  });

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const restoreToken = async () => {
      let userToken = null;
      try {
        await Auth.currentSession().then(userSession => {
          userToken = userSession.accessToken.jwtToken;
        });
        dispatch({ type: 'RESTORE_TOKEN', token: userToken });
      } catch (e) {
        dispatch({ type: 'CANCELL' });
      }
    };

    restoreToken();
  }, []);

  // collect our context values into a memoized object
  // memoized objects can help prevent unncessary re-renders by only changing
  // their value when one of their dependencies updates
  const authContext = useMemo(
    () => ({
      signIn: async data => {
        dispatch({ type: 'LOADING' });
        await Auth.signIn(data.email, data.password)
          .then(userSession => {
            dispatch({
              type: 'SIGN_IN',
              token: userSession.signInUserSession.accessToken.jwtToken,
            });
          })
          .catch(e => {
            dispatch({ type: 'CANCELL' });
            throw e;
          });
      },
      signOut: async () => {
        Auth.signOut().then(() => {
          dispatch({ type: 'SIGN_OUT' });
        });
      },
      signUp: async data => {
        dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' });
      },
      refreshToken: async () => {
        let newToken;
        await Auth.currentSession().then(userSession => {
          newToken = userSession.accessToken.jwtToken;
          dispatch({ type: 'RESTORE_TOKEN', token: newToken });
        });
        return newToken;
      },
      userToken: state.userToken,
      isAuthenticating: state.isAuthenticating,
    }),
    [state.userToken, state.isAuthenticating] //Important! To refresh the value where is being used
  );

  return (
    <AuthContext.Provider value={authContext} {...props}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Provides access to current auth data and callbacks.
 */
export const useAuthentication = () => {
  const context = useAuthContext();
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
