import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
export default function LoaderButton({
  isLoading,
  className = '',
  disabled = false,
  ...props
}) {
  return (
    <Button disabled={disabled || isLoading} {...props}>
      {isLoading ? (
        <>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading
        </>
      ) : (
        <>{props.children}</>
      )}
    </Button>
  );
}
