import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/shared/Header';
import MainRouter from './navigation/router';
import { AuthProvider } from './contexts/authContext';
import Interceptor from './client/Http';
import './App.css';

function App() {
  return (
    <Container fluid>
      <Router>
        <AuthProvider>
          <Interceptor />
          <Header />
          <MainRouter />
        </AuthProvider>
      </Router>
    </Container>
  );
}

export default App;
