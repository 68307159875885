export const authReducer = (prevState, action) => {
  switch (action.type) {
    case 'RESTORE_TOKEN':
      return {
        ...prevState,
        userToken: action.token,
        isAuthenticating: false,
      };
    case 'CANCELL':
      return {
        ...prevState,
        isAuthenticating: false,
      };
    case 'LOADING':
      return {
        ...prevState,
        isSignout: false,
        isAuthenticating: true,
      };
    case 'SIGN_IN':
      return {
        ...prevState,
        isSignout: false,
        userToken: action.token,
        isAuthenticating: false,
      };
    case 'SIGN_OUT':
      return {
        ...prevState,
        isSignout: true,
        userToken: null,
        isAuthenticating: false,
      };
    default:
      return {
        ...prevState,
        isSignout: false,
        userToken: null,
        isAuthenticating: false,
      };
  }
};
